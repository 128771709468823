import React, { ChangeEvent } from 'react';
import { ReactComponent as Search } from '../../assets/icons/search.svg';

interface TextInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

const TextInput: React.FC<TextInputProps> = ({ label, value, onChange, className }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={`${className} relative inline-block w-full`}>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        className="w-full h-[34px] px-3 bg-white border border-black rounded-[6px] appearance-none font-gothamPro text-[10px] font-normal"
        placeholder={label}
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <Search />
      </div>
    </div>
  );
};

export default TextInput;
