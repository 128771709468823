import React from "react";
import { Popup } from "react-map-gl";
import { GeoJSONFeature } from "../../../types/section";
import ObjectCard from "./objectCard";

interface PopupCardProps {
  selectedPlace: GeoJSONFeature | null;
  onClose: () => void;
  image: string;
}

const PopupCard: React.FC<PopupCardProps> = ({ selectedPlace, onClose, image }) => {
  if (!selectedPlace) return null;

  return (  
    <Popup
      longitude={selectedPlace.geometry.coordinates[0]}
      latitude={selectedPlace.geometry.coordinates[1]}
      closeOnClick={false}
      onClose={onClose}
      anchor="bottom"
    >
      <div className="p-[14px] w-[236px] flex items-start flex-col bg-white/90 backdrop-blur-3xl rounded-lg shadow-md font-mossport">
        <ObjectCard
          key={`obj-card-${selectedPlace.properties.objectId}`} // Используйте уникальный ключ
          object={selectedPlace.properties}
          img={image}
        />
      </div>
    </Popup>
  );
};

export default PopupCard;
