import React, { useState } from 'react';
import placeholderImage from "../../assets/images/placeholderImage.jpg";

interface ImageWithFallbackProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt?: string;
}

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  alt = "ФОТО ОТСУТСТВУЕТ",
  ...props
}) => {
  const [imageSrc, setImageSrc] = useState<string>(src);

  const handleError = () => {
    setImageSrc(placeholderImage);
  };

  return (
    <img
      src={imageSrc}
      alt={alt}
      onError={handleError}
      {...props}
    />
  );
};
