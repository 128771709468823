import React, { useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/preview-left.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/preview-right.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/preview-close.svg';
import './style.css';
import { ImageWithFallback } from './imageWithFallback';

interface ImageViewerProps {
  images: string[];
}

const ImageViewer: React.FC<ImageViewerProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  const openViewer = () => {
    setIsViewerOpen(true);
  };

  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <div className="image-viewer-container">
      <ImageWithFallback 
              src={images[0]}
              onClick={openViewer}
              className='cursor-pointer'
      />
      {isViewerOpen && (
        <div className="image-viewer">
          <div className="image-viewer-header">
            <button onClick={closeViewer} className="close-button">
              <CloseIcon />
            </button>
          </div>
          <div className="image-viewer-content">
            <button onClick={goToPrevious} className="nav-button left">
              <LeftArrowIcon />
            </button>
            <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="image" />
            <button onClick={goToNext} className="nav-button right">
              <RightArrowIcon />
            </button>
          </div>
          <div className="image-viewer-footer">
            {images.map((_, index) => (
              <div
                key={index}
                className={`dot ${index === currentIndex ? 'active' : ''}`}
              ></div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageViewer;
