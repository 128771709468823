import { District } from "../types/section";

export const MAP_STYLE_URL =
  "mapbox://styles/ilyashevchenko/ckmkaim7030bu17s6n5sr6k04";

export const SECTION_DETAILS = [
  "Физическая охрана",
  "Обслуживание пожарной сигнализации",
  "АХО",
  'Региональный проект "Спорт - норма жизни"',
];

export const ROUTES = {
  HOME: "/",
  SECTIONS: "/БЮДЖЕТ",
  MAP: "/КАРТА",
  MAP_WITH_SECTION: "/КАРТА/:sectionTitle",
  MAP_WITH_SUBSECTION: "/КАРТА/:sectionTitle/:subsectionTitle",
  OBJECT: "/КАРТА/ОБЪЕКТ/:id",
  SECTION_DETAILS: "/БЮДЖЕТ/:sectionTitle",
  MAP_WILDCARD: "/КАРТА/*",
  TABLE: '/ТАБЛИЦА',
  TABLE_WITH_SECTION: '/ТАБЛИЦА/:sectionTitle'
};

export const COLORS = {
  MAIN_RED: "#D8453C",
  LIGHT_RED: "#ebbdba",
  MAIN_GRAY: "#D7D7D7", 
  DARK_GRAY: "#494545"
};

export const DISTRICTS: District[] = [
  {
    title: 'ЮВАО',
    neighborhood: [
      'Некрасовка', 'Нижегородский', 'Печатники', 'Текстильщики', 'Южнопортовый', 
      'Кузьминки', 'Марьино', 'Капотня', 'Выхино-Жулебино', 'Рязанский', 'Лефортово'
    ]
  },
  {
    title: 'СВАО',
    neighborhood: [
      'Бутырский', 'Отрадное', 'Северный', 'Свиблово', 'Лианозово', 'Северное Медведково', 
      'Южное Медведково', 'Бибирево', 'Бабушкинский', 'Ярославский', 'Марфино', 
      'Алтуфьевский', 'Марьина роща', 'Алексеевский', 'Останкинский'
    ]
  },
  {
    title: 'ЮАО',
    neighborhood: [
      'Чертаново Северное', 'Бирюлево Западное', 'Орехово-Борисово Южное', 
      'Бирюлево Восточное', 'Царицыно', 'Чертаново Южное', 'Зябликово', 
      'Орехово-Борисово Северное', 'Москворечье-Сабурово', 'Нагатино-Садовники', 
      'Донской', 'Чертаново Центральное', 'Даниловский', 'Нагорный', 'Братеево'
    ]
  },
  {
    title: 'ЮЗАО',
    neighborhood: [
      'Южное Бутово', 'Черемушки', 'Ломоносовский', 'Ясенево', 'Теплый Стан', 
      'Котловка', 'Коньково', 'Обручевский', 'Академический', 'Северное Бутово', 
      'Гагаринский'
    ]
  },
  {
    title: 'Московская область',
    neighborhood: [
      'городской округ Чехов', 'городской округ Мытищи', 'городской округ Солнечногорск', 
      'городской округ Щёлково', 'Одинцовский городской округ'
    ]
  },
  {
    title: 'ЗАО',
    neighborhood: [
      'Крылатское', 'Кунцево', 'Ново-Переделкино', 'Внуково', 'Фили-Давыдково', 
      'Проспект Вернадского', 'Филевский парк', 'Тропарево-Никулино', 'Солнцево', 
      'Можайский', 'Раменки'
    ]
  },
  {
    title: 'САО',
    neighborhood: [
      'Хорошевский', 'Головинский', 'Дмитровский', 'Савеловский', 'Ховрино', 
      'Сокол', 'Восточное Дегунино', 'Бескудниковский', 'Коптево', 
      'Западное Дегунино', 'Левобережный', 'Беговой'
    ]
  },
  {
    title: 'ВАО',
    neighborhood: [
      'Соколиная Гора', 'Новогиреево', 'Восточное Измайлово', 'Северное Измайлово', 
      'Ивановское', 'Перово', 'Вешняки', 'Косино-Ухтомский', 'Восточный', 
      'Измайлово', 'Гольяново', 'Новокосино', 'Преображенское'
    ]
  },
  {
    title: 'ТАО',
    neighborhood: [
      'поселение Кленовское', 'поселение Краснопахорское', 'поселение Вороновское', 
      'поселение Первомайское'
    ]
  },
  {
    title: 'ЗелАО',
    neighborhood: [
      'Савелки', 'Старое Крюково', 'Силино', 'Крюково', 'Матушкино'
    ]
  },
  {
    title: 'СЗАО',
    neighborhood: [
      'Митино', 'Куркино', 'Строгино', 'Хорошево-Мневники', 'Покровское-Стрешнево', 
      'Южное Тушино', 'Щукино', 'Северное Тушино'
    ]
  },
  {
    title: 'ЦАО',
    neighborhood: [
      'Басманный', 'Красносельский', 'Пресненский', 'Таганский', 'Якиманка', 
      'Хамовники', 'Арбат', 'Мещанский', 'Тверской', 'Замоскворечье'
    ]
  },
  {
    title: 'НАО',
    neighborhood: [
      'поселение Сосенское'
    ]
  }
];

export const projectData2025: Array<{
  name: string;
  cost2025: number;
  objectCount2025: number;
  averageCost2025: number;
  cost2024: number;
  objectCount2024: number;
  averageCost2024: number;
  totalCostChange: number;
  averageCostChange: number;
}> = [
  {
    name: "Легковозводимые сооружения",
    cost2025: 1550000000,
    objectCount2025: 22,
    averageCost2025: 70454545.45,
    cost2024: 2194273191,
    objectCount2024: 27,
    averageCost2024: 81269377.44,
    totalCostChange: -29.36,
    averageCostChange: -13.31,
  },
  {
    name: "Комплексный капитальный ремонт",
    cost2025: 6226797400,
    objectCount2025: 65,
    averageCost2025: 95796883.08,
    cost2024: 7846374099,
    objectCount2024: 124,
    averageCost2024: 63277210.48,
    totalCostChange: -20.64,
    averageCostChange: 51.39,
  },
  {
    name: "Ремонтно-восстановительные работы",
    cost2025: 442793330,
    objectCount2025: 155,
    averageCost2025: 2856731.161,
    cost2024: 929331822.3,
    objectCount2024: 68,
    averageCost2024: 13666644.45,
    totalCostChange: -52.35,
    averageCostChange: -79.10,
  },
  {
    name: "Благоустройство",
    cost2025: 1570466300,
    objectCount2025: 38,
    averageCost2025: 41328060.53,
    cost2024: 2035292415,
    objectCount2024: 115,
    averageCost2024: 17698194.91,
    totalCostChange: -22.84,
    averageCostChange: 133.52,
  },
  {
    name: "Проектно-изыскательские работы",
    cost2025: 348306300,
    objectCount2025: 82,
    averageCost2025: 4247637.805,
    cost2024: 1419304755,
    objectCount2024: 429,
    averageCost2024: 3308402.693,
    totalCostChange: -75.46,
    averageCostChange: 28.39,
  },
];