import React from 'react';
import { GeoJSONFeature } from '../../../types/section';
import ObjectCard from './objectCard';

interface ObjectListProps {
  objects: GeoJSONFeature[];
}

const ObjectList: React.FC<ObjectListProps> = ({ objects }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 overflow-auto">
      {objects.map((object, idx) => (
        <div key={idx} className="pb-4 border-b border-[#A8A8A8]">
          <ObjectCard object={object.properties} />
        </div>
      ))}
    </div>
  );
};

export default ObjectList;
