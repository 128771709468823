import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/constants";
import { ReactComponent as WhiteLogo } from "../../assets/icons/logo-with-label.svg";
import { FC } from "react";

const Logo:FC<{className?: string}> = ({className}) => {
  const navigate = useNavigate();
  return (
      <WhiteLogo className={`${className} cursor-pointer`} onClick={() => navigate(ROUTES.SECTIONS)} />
  );
};

export default Logo;
