import * as XLSX from "xlsx";
import {
  FeatureProperties,
  GeoJSONFeature,
  MyGeoJSON,
  Section,
} from "../types/section";

export const parseGeoData = async (filePath: string): Promise<MyGeoJSON> => {
  const response = await fetch(filePath);

  if (!response.ok) {
    throw new Error(`Failed to fetch file: ${response.statusText}`);
  }

  const arrayBuffer = await response.arrayBuffer();

  try {
    const workbook = XLSX.read(arrayBuffer, { type: "array" });
    const sheetName = "Свод работ новый";
    const worksheet = workbook.Sheets[sheetName];

    if (!worksheet) {
      throw new Error(`Sheet "${sheetName}" not found`);
    }

    const getImagesForObject = (objectId: string): string[] => {
      const context = (require as any).context('../../public/photo', false, /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/);
    
      return context.keys()
        .filter((imagePath: string) => {
          const regex = new RegExp(`^./${objectId}_\\d+\\.(jpg|jpeg|png|JPG|JPEG|PNG)$`);
          return regex.test(imagePath);
        })
        .map((imagePath: string) => {
          const module = context(imagePath);
          return module.default || module;
        });
    };

    const jsonData = XLSX.utils.sheet_to_json<any>(worksheet, { header: 1 });
    const headers = jsonData[0]; // Первая строка — заголовки столбцов
    const dataRows = jsonData.slice(1); // Остальные строки — данные
    const features: GeoJSONFeature[] = dataRows.map((row: any[]) => {
      const featureProperties: FeatureProperties = {
        title: row[11] || "",
        address: row[headers.indexOf("Адрес объекта")] || "",
        section: row[headers.indexOf("Раздел")] || "",
        // images: row[headers.indexOf("Фото")]
        //   ? [row[headers.indexOf("Фото")]]
        //   : [],
        images: getImagesForObject(row[8]) || [],
        neighborhood: row[headers.indexOf("Район")] || "",
        district: row[headers.indexOf("Округ")] || "",
        activities: row[headers.indexOf("Виды работ")]
          ? row[headers.indexOf("Виды работ")].split(";")
          : [],
        price: row[headers.indexOf("Общая сумма договора, руб.")] || 0,

        subsection: row[headers.indexOf("Пункт")] || "",
        subsubsection: row[headers.indexOf("Подпункт")] || "",
        objectId: row[8] || "",
        serviceNumber: row[headers.indexOf("#service")] || "",
        correctedTitle:
          row[
            headers.indexOf("Наименование объекта/организации (исправленное)")
          ] || "",
        purpose: row[headers.indexOf("Назначение")] || "",
        numberOfContracts: row[headers.indexOf("Количество договоров")] || 0,
        contractTotalAmount:
          row[headers.indexOf("Общая сумма договора, руб.")] || 0,
        justification: row[headers.indexOf("Обоснование")] || "",
        applications: row[headers.indexOf("Заявки")] || "",
        notes: row[headers.indexOf("Примечание")] || "",
        numberOfPosts: row[headers.indexOf("Количество постов (охрана)")] || 0,
        area: row[headers.indexOf("Площадь")] || 0,
        yearOfConstruction: row[headers.indexOf("Год постройки")] || 0,
        annualVisitors: row[headers.indexOf("Посетителей в год")] || 0,
      };

      const feature: GeoJSONFeature = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            row[headers.indexOf("Долгота")] || 0,
            row[headers.indexOf("Широта")] || 0,
          ],
        },
        properties: featureProperties,
      };

      return feature;
    });

    // Всегда возвращаем объект MyGeoJSON
    return {
      type: "FeatureCollection",
      features: features,
    };
  } catch (error) {
    console.error("Error parsing Excel file:", error);

    // В случае ошибки возвращаем пустой объект MyGeoJSON
    return {
      type: "FeatureCollection",
      features: [],
    };
  }
};

export const parseSections = async (filePath: string): Promise<Section[]> => {
  const response = await fetch(filePath);
  const arrayBuffer = await response.arrayBuffer();
  const workbook = XLSX.read(arrayBuffer, { type: "array" });

  const sheetName = "Доли для первых экранов и карто";
  const worksheet = workbook.Sheets[sheetName];

  const jsonData = XLSX.utils.sheet_to_json<any>(worksheet, {
    header: 1,
    range: "A1:Z100",
  });
  const sections: Section[] = [];

  jsonData.forEach((row: any[]) => {
    const [
      sectionTitle,
      sectionCost,
      sectionPercentage,
      subsectionTitle,
      subsectionCost,
      subsectionPercentage,
      subsectionTotalPercentage,
      subsubsectionTitle,
      subsubsectionCost,
      subsubsectionPercentage,
      subsubsectionTotalPercentage,
    ] = row;

    if (sectionTitle) {
      sections.push({
        title: sectionTitle,
        cost: Number(sectionCost),
        percentage: parsePercentage(sectionPercentage),
        subsections: [],
      });
    }

    if (subsectionTitle && sections.length > 0) {
      // Добавляем пункт в последний раздел
      const lastSection = sections[sections.length - 1];
      lastSection.subsections?.push({
        title: subsectionTitle,
        cost: Number(subsectionCost),
        percentage: parsePercentage(subsectionPercentage),
        totalPecentage: parsePercentage(subsectionTotalPercentage),
        subsections: [],
      });
    }

    if (subsubsectionTitle && sections.length > 0) {
      // Добавляем подпункт в последний пункт последнего раздела
      const lastSection = sections[sections.length - 1];
      const lastSubsection =
        lastSection.subsections?.[lastSection.subsections.length - 1];
      if (lastSubsection) {
        lastSubsection.subsections?.push({
          title: subsubsectionTitle,
          cost: Number(subsubsectionCost),
          percentage: parsePercentage(subsubsectionPercentage),
          totalPecentage: parsePercentage(subsubsectionTotalPercentage),
        });
      }
    }
  });

  return sections.filter((section) => section.title !== "Раздел");
};

const parsePercentage = (percentage: any): number => {
  return parseFloat((Number(percentage) * 100).toFixed(2));
};
