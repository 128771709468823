import { useNavigate } from "react-router-dom";
import Selector from "../ui/selector";
import { ReactComponent as Logo } from "../../assets/icons/logo-black.svg";
import { ReactComponent as BackButton } from "../../assets/icons/back-button.svg";
import { DISTRICTS, ROUTES } from "../../constants/constants";
import TextInput from "../ui/textInput";
import { useAppContext } from "../../store/AppContext";

const MapHeader = ({
  total,
  count,
}: {
  total: number;
  count: number;
}) => {
  const { sections, geoData,  filters, setFilters } = useAppContext();

  const navigate = useNavigate();

  const handleInputChange = (name: string, value: string) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFilters({
      section: "",
      subsection: "",
      district: "",
      neighborhood: "",
      objectLabel: "",
    });
  };

  const sectionsToExclude = [
    "Региональный проект «Спорт – норма жизни» на 2025 год",
    "Аварийно-техническое обслуживание и ремонт инженерных систем",
    "Государственная программа города Москвы «Безопасный город»",
    "Обеспечение деятельности АУП организации",
    "Обеспечение технического присоединения объектов к сетям",
  ];
  const sectionOptions = sections
    .flatMap((section) =>
      section.subsections
        ? section.subsections.map((subsection) => subsection.title)
        : []
    )
    .filter((option) => !sectionsToExclude.includes(option));
  const subsectionOptions =
    sections
      .flatMap((section) => section.subsections)
      .find((subsection) => subsection?.title === filters.section)
      ?.subsections?.map((subsection) => subsection.title) || [];
  const districtOptions = DISTRICTS.map((district) => district.title);
  const organizationOptions = geoData.features
    .map((el) => el.properties.title)
    .filter((value, index, self) => self.indexOf(value) === index)
    .filter((el) => !el.includes("адрес") && el !== "");

  return (
    <div className="ml-[80px]">
      <div className="flex items-end justify-between">
        <Logo
          className="w-[185px] mt-[24px] cursor-pointer"
          onClick={() => navigate(ROUTES.SECTIONS)}
        />
        <BackButton
          className="cursor-pointer"
          onClick={() => navigate(ROUTES.SECTIONS)}
        />
      </div>
      <div className="flex items-end">
        <div className="font-mossport text-[54px]">ВЫБЕРИТЕ КОМПЛЕКС</div>
        <div className="font-mossport text-[32px] text-textGrey pb-[9px] pl-[16px]">{count} / {total} ОБЪЕКТОВ</div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-4">
          <Selector
            label="Статья расхода"
            options={sectionOptions}
            onChange={(value) => handleInputChange("section", value)}
            value={filters.section}
          />
          <Selector
            label="Подтип статьи расхода"
            options={subsectionOptions}
            onChange={(value) => handleInputChange("subsection", value)}
            value={filters.subsection}
            disabled={!filters.section}
          />
          <Selector
            label="Округ"
            options={districtOptions}
            onChange={(value) => handleInputChange("district", value)}
            value={filters.district}
          />
          <Selector
            label="Организация"
            options={organizationOptions}
            onChange={(value) => handleInputChange("organization", value)}
            value={filters.organization}
          />
        </div>
        <div className="flex flex-row gap-4">
          <TextInput
            label="Название объекта"
            value={filters.objectLabel}
            onChange={(value) => handleInputChange("objectLabel", value)}
          />
          <div
            onClick={handleReset}
            className="cursor-pointer w-[88px] h-[34px] border border-solid border-black rounded-[6px] px-3 font-gothamPro text-[10px] flex items-center"
          >
            Сбросить
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapHeader;
