import { useNavigate } from "react-router-dom";
import Logo from "../components/ui/Logo";
import { ROUTES } from "../constants/constants";
import BackgroundVideo from "../components/ui/backgroundVideo";
import { useAppContext } from "../store/AppContext";

const Home = () => {
  const navigate = useNavigate();
  const { sections, geoData } = useAppContext();

  return (
    <BackgroundVideo>
      <div className="min-h-screen py-[80px] px-[60px] ">
        <Logo className="" />
        <div
          className="text-white
        text-[100px]
        sm:text-[125px]
        md:text-[150px]
        lg:text-[175px]
        xl:text-[208px] 
        sm: w-[500px]
        md:w-[600px]
        lg:w-[750px]
        xl:w-[900px]
        leading-[1] 
        font-mossport
        mt-4 
        sm:mt-6 
        md:mt-8 
        lg:mt-10"
        >
          ПЛАН РАБОТ НА 2025 ГОД
        </div>
        <div
          className="flex justify-center items-center 
      mt-[100px]
      sm:mt-[125px]
      md:mt-[150px]
      lg:mt-[175px]
      xl:mt-[100px]
      "
        >
          <div
            onClick={() => navigate(ROUTES.SECTIONS)}
            className="cursor-pointer button-container font-gothamPro text-white text-[17px] font-bold py-[14px] px-[24px] w-[124px]"
          >
            НАЧАТЬ
          </div>
        </div>{" "}
      </div>
    </BackgroundVideo>
  );
};

export default Home;
