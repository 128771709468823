import { FC } from "react";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { ReactComponent as Quotes } from "../../../assets/icons/quotes.svg";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import { ReactComponent as OnMap } from "../../../assets/icons/onMap.svg";
import { ReactComponent as OnList } from "../../../assets/icons/onList.svg";
import { useNavigate } from "react-router-dom";
import { Section } from "../../../types/section";
import { projectData2025, ROUTES } from "../../../constants/constants";
import CircularProgressBar from "../../ui/CircularProgressBar";
import SubsectionElement from "./subsectionElement";
import { getObjectDeclension } from "../../../utils/utils";

interface ISectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  section: Section;
  color: string;
}

const SectionModal: FC<ISectionModalProps> = ({
  isOpen,
  onClose,
  section,
  color,
}) => {
  const navigate = useNavigate();
  const projectData = projectData2025.find((el) => el.name === section.title);

  const handleOnMapClick = () => {
    navigate(`${ROUTES.MAP}/${section.title}`);
  };

  const handleOnTableClick = () => {
    navigate(`${ROUTES.TABLE}/${section.title}`);
  };

  const renderComparison = () => {
    if (!projectData) return null;
    return (
      <div className="flex justify-between text-[10px] absolute bottom-[-100px]">
        <div>
          <div className="text-textGrey">2024</div>
          <div className="grid gap-[10px] pt-1">
            <div>
              <span className="text-[14px] inline-block">
                {(projectData.cost2024 / 1e9).toFixed(1)}
              </span>
              <span> млрд руб.</span>
            </div>
            <div>
              <span className="text-[14px] inline-block">
                {(projectData.averageCost2024 / 1e6).toFixed(1)}
              </span>
              <span>
                {" "}
                млн руб.
                <br />/ объект
              </span>
            </div>
            <div>
              <span className="text-[14px] inline-block mt-[-15px]">
                {projectData.objectCount2024}
              </span>
              <span> {getObjectDeclension(projectData.objectCount2024)}</span>
            </div>
          </div>
        </div>
        <Quotes className="mx-2 my-2" />
        <div>
          <div className="text-textGrey">2025</div>
          <div className="pt-1 flex flex-col gap-[2px]">
            <div>
              <span className="text-[14px] inline-block">
                {(projectData.cost2025 / 1e9).toFixed(1)}
              </span>
              <span> млрд руб.</span>
              <br />
              <span className="text-textGrey">
                {projectData.totalCostChange > 0 && "+"}{" "}
                {projectData.totalCostChange.toFixed(0)} %
              </span>
            </div>
            <div>
              <span className="text-[14px] inline-block">
                {(projectData.averageCost2025 / 1e6).toFixed(1)}
              </span>
              <span> млн руб.</span>
              <br />
              <span className="text-textGrey">
                {projectData.averageCostChange > 0 && "+"}{" "}
                {projectData.averageCostChange.toFixed(0)} %
              </span>
            </div>
            <div>
              <span className="text-[14px] inline-block">
                {projectData.objectCount2025}
              </span>
              <span> {getObjectDeclension(projectData.objectCount2025)}</span>
              <br />
              <span className="text-textGrey">
                {projectData.objectCount2025 - projectData.objectCount2024 >
                  0 && "+"}{" "}
                {projectData.objectCount2025 - projectData.objectCount2024}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!isOpen) return null;

  const disableMap = ['Оборудование и инвентарь', 'Аварийно-техническое обслуживание и ремонт инженерных систем', 'Региональный проект «Спорт – норма жизни» на 2025 год', 'Обеспечение технического присоединения объектов к сетям', 'Государственная программа города Москвы «Безопасный город»', 'Энергосбережение', 'Техническое облуживание машин, оборудования, зданий, сооружений', 'Обеспечение деятельности АУП организации'].includes(section.title)
  
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm z-50"
      onClick={onClose}
    >
      <div
        className={`bg-white w-[680px] p-[30px] rounded-[20px] relative max-h-[800px] h-[500px]`}
        onClick={(e) => e.stopPropagation()}
      >
        <Close
          className="cursor-pointer absolute top-[16px] right-[16px]"
          onClick={onClose}
        />
        <div className="flex  absolute bottom-[16px] right-[16px] ">
          { !disableMap && 
          <OnMap
            className="cursor-pointer h-[28px]"
            onClick={handleOnMapClick}
          />
}
          <OnList
            className="cursor-pointer h-[28px] ml-2"
            onClick={handleOnTableClick}
          />
        </div>
        <div className="flex flex-row h-full">
          <div className="flex-col w-[210px] h-full">
            <div className="font-bold text-[22px] pb-8">{section.title}</div>
            <div className="relative w-[200px] h-[200px]">
              <CircularProgressBar
                size={200}
                percentage={section.percentage}
                grayStrokeWidth={8}
                activeColor={color}
              >
                <div className={`font-mossport text-${color} text-[54px] pb-2`}>
                  {(section.cost / 1e9).toFixed(2)}
                  <div className="text-[36px]">МЛРД РУБ.</div>
                </div>
                <div className="text-[15px] font-gothamPro">
                  {section.percentage.toFixed(2)}%
                </div>
              </CircularProgressBar>
              {projectData && renderComparison()}
            </div>
          </div>
          <div className="flex-col pl-6 w-full mb-[24px] overflow-y-auto">
            {section.subsections
              ?.sort((a, b) => b.percentage - a.percentage)
              .map((subsection, idx) => (
                <div className="w-full" key={`subsection-${idx}`}>
                  <SubsectionElement
                    section={subsection}
                    color={color}
                    fatherTitle={section.title}
                  />
                  {section.subsections &&
                    idx < section.subsections.length - 1 && (
                      <div className="w-full h-[1px] my-[12px] bg-mainGrey" />
                    )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionModal;
