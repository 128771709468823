import { useNavigate } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import { ReactComponent as Aim } from "../../../assets/icons/aim.svg";
import { FC } from "react";
import { FeatureProperties } from "../../../types/section";
import { ImageWithFallback } from "../../ui/imageWithFallback";

interface ObjectCardProps {
  object: FeatureProperties;
  img?: string;
}

const ObjectCard: FC<ObjectCardProps> = ({ object, img }) => {
  const navigate = useNavigate();

  const handleMoreDetailsClick = () => {
    navigate(`/КАРТА/ОБЪЕКТ/${object.objectId}`);
  };

  return (
    <div
      onClick={handleMoreDetailsClick}
      className="bg-transparent rounded-lg cursor-pointer h-full flex flex-col justify-between"
    >
      <div>
        <ImageWithFallback
          src={img || object.images?.[0] || ""}
          alt="ФОТО ОТСУТСТВУЕТ"
          className="w-full h-[150px] object-cover rounded-[10px]"
        />
        <div className="flex justify-between items-start pt-[10px] text-[20px] font-mossport leading-[1]">
          <div className="flex-1 pr-[10px]">{object.title.toUpperCase()}</div>
          <div className="text-mainRed whitespace-nowrap text-right">
            {Number((object.price / 1_000_000).toFixed(1)).toLocaleString(
              "ru-RU"
            )}
            <br />
            МЛН РУБ.
          </div>
        </div>
      </div>
      <div className="pt-[10px] flex">
        <div className="flex-grow font-gothamPro leading-[1] text-[10px] max-w-[190px]">
          <div className="flex pt-[10px]">
            <Aim className="min-w-[18px] min-h-[18px]" />
            <div className="pl-[6px]">
              {object.district} <br />
              {object.address}
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 self-end">
          <Arrow className="cursor-pointer w-[20px] h-[20px]" />
        </div>
      </div>
    </div>
  );
};

export default ObjectCard;
