import { useEffect, useMemo, useState } from "react";
import "./style.css";
import { Filters, MyGeoJSON } from "../types/section";
import { useParams } from "react-router-dom";
import { useAppContext } from "../store/AppContext";
import TableHeader from "../components/complexElements/tableHeader";
import ElementTable from "../components/ui/table";
import { ROUTES } from "../constants/constants";

const Table = () => {
  const { sectionTitle } = useParams();
  const { geoData, setLastVisited } = useAppContext();

  const [filters, setFilters] = useState<Filters>({
    section: "",
    subsection: "",
    district: "",
    organization: "",
    objectLabel: "",
  });

  useEffect(() => {
    if (sectionTitle) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        section: sectionTitle,
      }));
    }
  }, [sectionTitle]);

  useEffect(() => {
    setLastVisited(ROUTES.TABLE);
  }, [])

  const filteredGeoData: MyGeoJSON = useMemo(() => {
    const uniqueIds = new Set<string>();
    return {
      ...geoData,
      features: geoData.features.filter((feature) => {
        const { coordinates } = feature.geometry;
        const {
          subsection,
          subsubsection,
          district,
          title,
          address,
          objectId,
        } = feature.properties;
        if (uniqueIds.has(objectId)) {
          return false;
        }
        uniqueIds.add(objectId);
        return (
          coordinates[0] !== 0 &&
          coordinates[1] !== 0 &&
          (!filters.section || subsection === filters.section) &&
          (!filters.subsection || subsubsection === filters.subsection) &&
          (!filters.district || district === filters.district) &&
          (!filters.organization || title === filters.organization) &&
          (!filters.objectLabel ||
            title.toLowerCase().includes(filters.objectLabel.toLowerCase()) ||
            address.toLowerCase().includes(filters.objectLabel.toLowerCase()))
        );
      }),
    };
  }, [filters, sectionTitle]);

  return (
    <div style={{ display: "flex", height: "100vh", minWidth: "800px" }}>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          padding: "0 80px", 
          boxSizing: "border-box", 
          maxWidth: "100%", 
          overflowX: "hidden",
        }}
      >
        <div>
          <TableHeader />
        </div>
        <div className="my-[32px] flex-1">
          <ElementTable data={filteredGeoData} />
        </div>
      </div>
    </div>
  );
};

export default Table;
