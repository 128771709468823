import { useParams } from "react-router-dom";

const SectionDetails = () => {
  const { sectionTitle } = useParams();
  return (
    <div>
      <div className="bg-lightBlue text-lightBlue"></div>
      <div className="bg-backgroundGrey text-darkBlue"></div>
      <div className="bg-darkBlue text-darkBlue"></div>
      <div className="text-darkBlue"/>{sectionTitle}
    </div>
  );
};

export default SectionDetails;
