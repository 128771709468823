import { useNavigate } from "react-router-dom";
import Selector from "../ui/selector";
import { ReactComponent as Logo } from "../../assets/icons/logo-black.svg";
import { ReactComponent as BackButton } from "../../assets/icons/back-button.svg";
import { ReactComponent as OnMap } from "../../assets/icons/bigOnMap.svg";
import { DISTRICTS, ROUTES } from "../../constants/constants";
import TextInput from "../ui/textInput";
import { useAppContext } from "../../store/AppContext";

const TableHeader = () => {
  const { sections, geoData, filters, setFilters } = useAppContext();

  const navigate = useNavigate();

  const handleInputChange = (name: string, value: string) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFilters({
      section: "",
      subsection: "",
      district: "",
      organization: "",
      objectLabel: "",
    });
  };

  const sectionOptions = sections.flatMap((section) =>
    section.subsections
      ? section.subsections.map((subsection) => subsection.title)
      : []
  );
  const subsectionOptions =
    sections
      .flatMap((section) => section.subsections)
      .find((subsection) => subsection?.title === filters.section)
      ?.subsections?.map((subsection) => subsection.title) || [];
  const districtOptions = DISTRICTS.map((district) => district.title);
  const organizationOptions = geoData.features
    .map((el) => el.properties.title)
    .filter((value, index, self) => self.indexOf(value) === index)
    .filter((el) => !el.includes("адрес") && el !== "");

  return (
    <div className="max-w-full">
      {" "}
      {/* Устанавливаем максимальную ширину в 100% */}
      <div className="flex items-end justify-between">
        <Logo
          className="w-[185px] mt-[24px] cursor-pointer"
          onClick={() => navigate(ROUTES.SECTIONS)}
        />
        <div className="flex gap-4">
          <OnMap
            className="cursor-pointer"
            onClick={() =>
              navigate(`${ROUTES.MAP}/${filters.section}/${filters.subsection}`)
            }
          />
          <BackButton
            className="cursor-pointer"
            onClick={() => navigate(ROUTES.SECTIONS)}
          />
        </div>
      </div>
      <div className="font-mossport text-[54px]">РАСХОДЫ 2025</div>
      <div className="flex gap-4">
        <TextInput
          label="Название объекта"
          value={filters.objectLabel}
          onChange={(value) => handleInputChange("objectLabel", value)}
          className="min-w-[380px]"
        />
        <Selector
          label="Статья расхода"
          options={sectionOptions}
          onChange={(value) => handleInputChange("section", value)}
          value={filters.section}
        />
        <Selector
          label="Подтип статьи расхода"
          options={subsectionOptions}
          onChange={(value) => handleInputChange("subsection", value)}
          value={filters.subsection}
          disabled={!filters.section}
        />
        <Selector
          label="Округ"
          options={districtOptions}
          onChange={(value) => handleInputChange("district", value)}
          value={filters.district}
        />
        <Selector
          label="Организация"
          options={organizationOptions}
          onChange={(value) => handleInputChange("organization", value)}
          value={filters.organization}
        />
        <div
          onClick={handleReset}
          className="cursor-pointer w-[88px] h-[34px] border border-solid border-black rounded-[6px] px-3 font-gothamPro text-[10px] flex items-center"
        >
          Сбросить
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
