/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/icons/logo-black.svg";
import { ReactComponent as Aim } from "../assets/icons/aim.svg";
import { ReactComponent as Man } from "../assets/icons/running-man.svg";
import { ROUTES } from "../constants/constants";
import { ReactComponent as BackButton } from "../assets/icons/back-button.svg";
import ReactMapGl, { Marker, NavigationControl } from "react-map-gl";
import "./style.css";
import { MAP_STYLE_URL } from "../constants/constants";
import { useAppContext } from "../store/AppContext";
import placemarkRed from "../assets/icons/placemark-red.png";
import placemarkBlue from "../assets/icons/placemark-blue.png";
import placemarkMix from "../assets/icons/placemark-mix.png";
import ImageViewer from "../components/ui/imageViewer";

const ObjectDetails: React.FC = () => {
  const { geoData, loading, lastVisited } = useAppContext();

  if (loading) {
    return <div>Загрузка...</div>;
  }

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const objects = geoData.features.filter(
    (el) => Number(el.properties.objectId) === Number(id)
  );

  const currentObject = objects[0];

  const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;
  const [viewport, setViewport] = useState({
    longitude: currentObject.geometry.coordinates[0],
    latitude: currentObject.geometry.coordinates[1],
    zoom: 16,
  });

  const detailsList = objects.map((ob) => ({
    price: ob.properties.price,
    subsection: ob.properties.subsection,
    subsubsection: ob.properties.subsubsection,
  }));

  const totalSum = objects.reduce(
    (sum, obj) => sum + Number(obj.properties.price),
    0
  );

  const ProgramDetails = () => {
    return (
      <div className="p-[24px] mr-[60px] w-1/2 shadow-xl rounded-[20px] h-full mt-[-25px] min-h-[700px]  flex flex-col justify-between">
        <div className="flex justify-between font-gothamPro text-[16px] font-bold text-textGrey leading-[1]">
          <div>
            <div className="flex pt-[24px] items-end">
              <div className="font-mossport text-[70px] text-mainRed font-normal">
                {Number((totalSum / 1_000_000).toFixed(2)).toLocaleString(
                  "ru-RU"
                )}{" "}
                МЛН РУБ.
              </div>
            </div>
            <div className="pt-1">стоимость мероприятий</div>
          </div>
          <Man className="w-[112px] h-[112px] opacity-20" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 py-4">
          {detailsList.map((item, idx) => (
            <div key={`activity-${idx}`} className="flex items-start">
              <div className="mr-3 mt-2 text-mainRed font-gothamPro font-bold text-[16px] flex-shrink-0 w-8 h-8 border-2 border-mainRed rounded-full flex items-center justify-center">
                {idx + 1}
              </div>
              <div className="flex-1">
                <div className="font-mossport text-[28px]">
                  {(item.price / 1e6).toFixed(1)} МЛН РУБ.
                </div>
                <div className="font-gothamPro text-[12px]">
                  {item.subsection}
                  <br />
                  <div className="text-textGrey">{item.subsubsection}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="overflow-hidden rounded-lg shadow-md"
          style={{ height: "300px" }}
        >
          <ImageViewer images={currentObject.properties.images || []} />
        </div>
      </div>
    );
  };

  return (
    <div className="pl-[80px] h-full bg-cover bg-no-repeat bg-center">
      <div className="flex justify-between pr-[50px] pt-[36px]">
        <Logo
          className="w-[185px] cursor-pointer"
          onClick={() => navigate(ROUTES.SECTIONS)}
        />
        <BackButton
          className="cursor-pointer"
          onClick={() => navigate(lastVisited)}
        />
      </div>
      <div className="font-mossport text-mainRed text-[64px] pt-[12px]">
        {currentObject.properties.title.toUpperCase()}
      </div>
      <div className="flex justify-between">
        <div className="flex-col w-1/2 pr-[60px] flex justify-between">
          <div className="font-gothamPro text-[20px]">
            <div className="flex">
              <Aim className="min-w-[26px] min-h-[26px]" />
              <div className="pl-[6px]">
                {currentObject.properties.district} <br />
                {currentObject.properties.address}
              </div>
            </div>
          </div>
          {/* <div className="flex justify-between pt-[24px]">
            <div>
              <div className="font-mossport text-[45px] leading-[1]">
                {visitsPerMonth}
              </div>
              <div className="font-gothamPro text-[20px]">
                посещений в месяц
              </div>
            </div>
            <div>
              <div className="font-mossport text-[45px] leading-[1]">
                {area} М²
              </div>
              <div className="font-gothamPro text-[20px]">площадь</div>
            </div>
            <div>
              <div className="font-mossport text-[45px] leading-[1]">
                {yearOfConstruction}
              </div>
              <div className="font-gothamPro text-[20px]">год постройки</div>
            </div>
          </div> */}
          <ReactMapGl
            {...viewport}
            mapStyle={MAP_STYLE_URL}
            onMove={(evt) => setViewport(evt.viewState)}
            mapboxAccessToken={mapboxToken}
            interactiveLayerIds={["point-layer"]}
            style={{
              marginTop: 16,
              width: "100%",
              height: "100vh",
              maxHeight: 600,
              flexGrow: 1,
              borderRadius: "20px",
              overflow: "hidden",
            }}
            onLoad={(event) => {
              const map = event.target;
              map.loadImage(placemarkRed, (error, image) => {
                if (error) {
                  return;
                }
                if (image) {
                  map.addImage("custom-marker-red", image);
                }
              });
              map.loadImage(placemarkBlue, (error, image) => {
                if (error) {
                  return;
                }
                if (image) {
                  map.addImage("custom-marker-blue", image);
                }
              });
              map.loadImage(placemarkMix, (error, image) => {
                if (error) {
                  return;
                }
                if (image) {
                  map.addImage("custom-marker-mix", image);
                }
              });
            }}
          >
            <Marker
              longitude={currentObject.geometry.coordinates[0]}
              latitude={currentObject.geometry.coordinates[1]}
            >
              <img
                src={
                  currentObject.properties.color === "red"
                    ? placemarkRed
                    : currentObject.properties.color === "blue"
                    ? placemarkBlue
                    : placemarkMix
                }
                alt="Custom Marker"
              />
            </Marker>
            <NavigationControl />
          </ReactMapGl>
        </div>
        <ProgramDetails />
      </div>
    </div>
  );
};

export default ObjectDetails;
