import React, { useEffect, useRef } from 'react';

interface CircularProgressProps {
  size: number;
  percentage: number;
  grayStrokeWidth: number;
  activeColor: string;
  children?: React.ReactNode;
}

const CircularProgressBar: React.FC<CircularProgressProps> = ({ size, percentage, grayStrokeWidth, activeColor, children }) => {
  const redStrokeWidth = grayStrokeWidth * 2;
  const radius = (size - redStrokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  const getColor = () => {
    if (activeColor === 'mainRed') return '#D8453C';
    if (activeColor === 'lightBlue') return '#7BA3B5';
    if (activeColor === 'darkBlue') return '#475E93';
    return '#D8453C';
  };

  const adjustColorIntensity = (color: string, intensity: number) => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const newR = Math.round(r + (255 - r) * intensity).toString(16).padStart(2, '0');
    const newG = Math.round(g + (255 - g) * intensity).toString(16).padStart(2, '0');
    const newB = Math.round(b + (255 - b) * intensity).toString(16).padStart(2, '0');

    return `#${newR}${newG}${newB}`;
  };

  const passiveColor = adjustColorIntensity(getColor(), 0.8); // 20% интенсивность

  const circleRef = useRef<SVGCircleElement>(null);

  useEffect(() => {
    if (circleRef.current) {
      circleRef.current.style.transition = 'stroke-dashoffset 1s';
      circleRef.current.getBoundingClientRect();
      circleRef.current.style.strokeDashoffset = String(offset);
    }
  }, [offset]);

  return (
    <div style={{ position: 'relative', width: size, height: size }}>
      <svg width={size} height={size}>
        <circle
          stroke={passiveColor}
          fill="transparent"
          strokeWidth={grayStrokeWidth}
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          ref={circleRef}
          stroke={getColor()}
          fill="transparent"
          strokeWidth={redStrokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={circumference}
          strokeLinecap="round"
          r={radius}
          cx={size / 2}
          cy={size / 2}
          style={{ transform: 'rotate(-90deg)', transformOrigin: '50% 50%' }}
        />
      </svg>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          width: '100%',
          height: '100%'
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CircularProgressBar;
